<template>
  <div
    id="seckill"
    v-cloak
    v-loading.fullscreen="loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="head">
      <img
        src="../../assets/img/seckill/headimg.jpg"
        draggable="false"
        alt=""
      />
      <span>每天{{ seckill.times }}开始 &nbsp; 先到先得</span>
    </div>
    <div class="content">
      <!-- <div class="switch-box">
          <div
            @click="switchTime(item)"
            class="item"
            :class="item.id==timeSelectd?'active':''"
            v-for="item in timeArr"
          >
            <p>{{item.time}}</p>
            <p>{{item.times}}-{{item.timed}}</p>
            <div>{{item.title}}</div>
          </div>
        </div> -->
      <div class="switch-box1">
        <div
          class="item_box"
          @click="switchTime(item)"
          :class="item.id == timeSelectd ? 'active' : ''"
          v-for="item in timeArr"
          :key="item.id"
        >
          <div class="blank_left"></div>
          <div style="background-color: red">
            <div class="item">
              <p class="time">
                {{ handleTime(item.start_time) }}-{{
                  handleTime(item.countdown_time)
                }}
              </p>
              <p class="text">{{ item.countdown_title }}</p>
              <div class="timer_num" v-if="item.id == timeSelectd">
                <span>{{ timer.hours }}</span>
                :
                <span>{{ timer.minutes }}</span>
                :
                <span>{{ timer.seconds }}</span>
              </div>
              <div v-else class="timer_num">{{ judgmentTime(item) }}</div>
            </div>
          </div>
          <div class="blank_right"></div>
        </div>
      </div>
      <!-- <div class="timer">
          <div class="timerBox">
            {{timeText}}
            <span>{{timer.days}}</span>
            天
            <span>{{timer.hours}}</span>
            时
            <span>{{timer.minutes}}</span>
            分
            <span>{{timer.seconds}}</span>
            秒
          </div>
        </div> -->

      <!-- <div class="tip_box" style="background-image: url(../../assets/img/seckill/tip_img.png);">
          <p class="tip_title">温馨提示</p>
          <div class="tip_text">
            <p class="">1.所有订单一旦包含爆品秒杀品种都需满足200元起购门槛</p>
            <p class="">2.订单如果只有爆款秒杀品种无法提交订单</p>
            <p class="">3.订单里必须包含有除秒杀（爆款秒杀/限时秒杀）之外的品种才能提交订单</p>
          </div>
        </div> -->
      <template v-if="timeIsTrue">
        <!-- 爆款秒杀品种只有一种 -->
        <template v-if="goodsData_bk.length === 1">
          <div v-for="item in goodsData_bk" :key="item.id" class="bkms_box">
            <div class="goods_bk">
              <img class="goods_bk_img" :src="item.logo" alt="" />
              <div class="goods_bk_text">
                <p class="title">{{ item.title }}</p>
                <p class="ggxq">
                  <span>规格：{{ item.sku }}</span>
                  <span>效期：{{ item.validity_date }}</span>
                </p>
                <p class="over_nowrop">厂家：{{ item.scqy }}</p>
                <div class="bkms_kcxl">
                  <span>库存：{{ item.zskc }}</span>
                  <div class="btn_xl">
                    <p>固定抢购量</p>
                    <p>{{ item.regular_num }}</p>
                  </div>
                </div>
                <el-tooltip
                  popper-class="numTip"
                  effect="dark"
                  :content="'剩余：' + item.sykc"
                  placement="top-start"
                >
                  <el-progress
                    color="#f13a3a"
                    :class="item.sykc == 0 ? 'actice_yqg' : ''"
                    :stroke-width="22"
                    :show-text="false"
                    :percentage="setPercent(item)"
                    status="exception"
                  ></el-progress>
                </el-tooltip>
                <div
                  v-if="item.show_cart == '101'"
                  class="bkms_btn"
                  @click="buyGoods(item)"
                >
                  <span>秒杀价￥</span>
                  <span>{{ item.price }}</span>
                  <img src="../../assets/img/seckill/bkms_cart.png" alt="" />
                </div>
                <div v-else-if="item.sykc == 0" class="bkms_btn">
                  <p class="Begone">已抢光</p>
                </div>
                <div v-else class="bkms_btn">
                  <p class="Begone">{{ item.price }}</p>
                </div>
              </div>
            </div>
            <p class="bkms_tip">
              <i class="el-icon-warning"></i> {{ limit_str }}
            </p>
          </div>
        </template>
        <!-- 爆款秒杀品种有两种 -->
        <template v-else-if="goodsData_bk.length === 2">
          <div class="bkms_box_two">
            <div class="goods_bk" v-for="item in goodsData_bk" :key="item.id">
              <img class="goods_bk_img" :src="item.logo" alt="" />
              <div class="goods_bk_text">
                <p class="title">{{ item.title }}</p>
                <p class="ggxq">规格：{{ item.sku }}</p>
                <p class="ggxq">效期：{{ item.validity_date }}</p>
                <p class="over_nowrop">厂家：{{ item.scqy }}</p>
                <div class="bkms_kcxl">
                  <span>库存：{{ item.zskc }}</span>
                  <div class="btn_xl">
                    <p>固定抢购量</p>
                    <p>{{ item.regular_num }}</p>
                  </div>
                </div>
                <el-tooltip
                  popper-class="numTip"
                  effect="dark"
                  :content="'剩余：' + item.sykc"
                  placement="top-start"
                >
                  <el-progress
                    color="#f13a3a"
                    :class="item.sykc == 0 ? 'actice_yqg' : ''"
                    :stroke-width="12"
                    :show-text="false"
                    :percentage="setPercent(item)"
                    status="exception"
                  ></el-progress>
                </el-tooltip>
                <div
                  v-if="item.show_cart == '101'"
                  class="bkms_btn"
                  @click="buyGoods(item)"
                >
                  <span>秒杀价￥</span>
                  <span>{{ item.price }}</span>
                  <img src="../../assets/img/seckill/bkms_cart.png" alt="" />
                </div>
                <div v-else-if="item.sykc == 0" class="bkms_btn">
                  <p class="Begone">已抢光</p>
                </div>
                <div v-else class="bkms_btn">
                  <p class="Begone">{{ item.price }}</p>
                </div>
              </div>
            </div>
            <p class="bkms_tip_two">
              <i class="el-icon-warning"></i> {{ limit_str }}
            </p>
          </div>
        </template>

        <!-- 爆款秒杀品种有三种 -->
        <template v-else-if="goodsData_bk.length === 3">
          <div class="bkms_box_three">
            <div class="goods_bk" v-for="item in goodsData_bk" :key="item.id">
              <img class="goods_bk_img" :src="item.logo" alt="" />
              <div class="goods_bk_text">
                <p class="title">{{ item.title }}</p>
                <p class="ggxq">
                  <span>规格：{{ item.sku }}</span>
                  <span>效期：{{ item.validity_date }}</span>
                </p>
                <p class="over_nowrop">厂家：{{ item.scqy }}</p>
                <div class="bkms_kcxl">
                  <span>库存：{{ item.zskc }}</span>
                  <div class="btn_xl">
                    <p>固定抢购量</p>
                    <p>{{ item.regular_num }}</p>
                  </div>
                </div>
                <div class="btn_three">
                  <el-tooltip
                    popper-class="numTip"
                    effect="dark"
                    :content="'剩余：' + item.sykc"
                    placement="top-start"
                  >
                    <el-progress
                      color="#f13a3a"
                      :class="item.sykc == 0 ? 'actice_yqg' : ''"
                      :stroke-width="10"
                      :show-text="false"
                      :percentage="setPercent(item)"
                      status="exception"
                    ></el-progress>
                  </el-tooltip>
                  <div
                    v-if="item.show_cart == '101'"
                    class="bkms_btn"
                    @click="buyGoods(item)"
                  >
                    <span>秒杀价￥</span>
                    <span>{{ item.price }}</span>
                    <img src="../../assets/img/seckill/bkms_cart.png" alt="" />
                  </div>
                  <div v-else-if="item.sykc == 0" class="bkms_btn">
                  <p class="Begone">已抢光</p>
                </div>
                  <div v-else class="bkms_btn">
                    <p class="Begone">{{ item.price }}</p>
                  </div>
                </div>
              </div>
            </div>
            <p class="bkms_tip_two">
              <i class="el-icon-warning"></i> {{ limit_str }}
            </p>
          </div>
        </template>

        <!-- 爆款秒杀品种大于三种 -->
        <template v-else-if="goodsData_bk.length > 3">
          <div class="tag_box">
            <img src="../../assets/img/seckill/tag_img.png" alt="" />
            <p class="tag_title">爆款秒杀</p>
          </div>
          <p class="tip_text">
            <i class="el-icon-warning"></i> {{ limit_str }}
          </p>
          <div class="goodsbox">
            <div class="item" v-for="item in goodsData_bk" :key="item.id">
              <img
                class="tag_img"
                style="width: 50px"
                src="../../assets/img/seckill/hot_bk.png"
                alt=""
              />
              <div class="imgbox">
                <img :src="item.logo" alt="" />
              </div>
              <div class="textbox">
                <p class="title">{{ item.title }}</p>
                <p>规格：{{ item.sku }}</p>
                <p>厂家：{{ item.scqy }}</p>
                <p>
                  <span class="widSpan">效期：{{ item.validity_date }}</span>
                </p>
                <div class="kcxl">
                  <span>库存：{{ item.zskc }}</span>
                  <div class="btn_xl">
                    <p>固定抢购量</p>
                    <p>{{ item.regular_num }}</p>
                  </div>
                </div>
                <div class="dashedLine"></div>
                <div class="priceBox">
                  <div>
                    会员价：<s>{{ item.price1 }}</s>
                  </div>
                  <div>
                    秒杀价:<span class="red_price">{{ item.price }}</span>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5px;
                  "
                >
                  <el-tooltip
                    popper-class="numTip"
                    effect="dark"
                    :content="'剩余：' + item.sykc"
                    placement="top-start"
                  >
                    <el-progress
                      color="#f13a3a"
                      :class="item.sykc == 0 ? 'actice_yqg' : ''"
                      :stroke-width="10"
                      :show-text="false"
                      :percentage="setPercent(item)"
                      status="exception"
                    ></el-progress>
                  </el-tooltip>
                  <div
                    v-if="item.show_cart == '101' && item.sykc > 0"
                    class="btns_gm"
                    @click="buyGoods(item)"
                  >
                    {{ btnText }}
                  </div>
                  <div
                    v-else-if="item.sykc===0"
                    class="btns_gm btn_h"
                  >
                    已抢光
                  </div>
                  <div v-else class="btns_gm btn_h">{{ item.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="goodsData_xs.length > 0">
          <div class="tag_box">
            <img src="../../assets/img/seckill/tag_img.png" alt="" />
            <p class="tag_title">限时秒杀</p>
          </div>
          <div class="goodsbox" v-if="goodsData_xs.length > 0">
            <div class="item" v-for="item in goodsData_xs" :key="item.id">
              <img
                class="tag_img"
                style="width: 70px;"
                src="../../assets/img/seckill/mstag.png"
                alt=""
              />
              <div class="imgbox">
                <img :src="item.logo" alt="" />
              </div>
              <div class="textbox">
                <p class="title">{{ item.title }}</p>
                <p>规格：{{ item.sku }}</p>
                <p>厂家：{{ item.scqy }}</p>
                <p>
                  <span class="widSpan">效期：{{ item.validity_date }}</span>
                </p>
                <div class="kcxl">
                  <span>库存：{{ item.zskc }}</span>
                  <div class="btn_xl">
                    <p>固定抢购量</p>
                    <p>{{ item.regular_num }}</p>
                  </div>
                </div>
                <div class="dashedLine"></div>
                <!-- <p class="tag">
                <span class="tags">活动</span>
                秒杀活动{{btnText=='立即购买'?'进行中':btnText}}
              </p> -->
                <div class="priceBox">
                  <div>
                    会员价：<s>{{ item.price1 }}</s>
                  </div>
                  <div>
                    秒杀价:<span class="red_price">{{ item.price }}</span>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5px;
                  "
                >
                  <el-tooltip
                    popper-class="numTip"
                    effect="dark"
                    :content="'剩余：' + item.sykc"
                    placement="top-start"
                  >
                    <el-progress
                      color="#f13a3a"
                      :class="item.sykc == 0 ? 'actice_yqg' : ''"
                      :stroke-width="10"
                      :show-text="false"
                      :percentage="setPercent(item)"
                      status="exception"
                    ></el-progress>
                  </el-tooltip>
                  <div
                    v-if="item.show_cart == '101' && item.sykc > 0"
                    class="btns_gm"
                    @click="buyGoods(item)"
                  >
                    {{ btnText }}
                  </div>
                  <div
                    v-else-if="item.sykc===0"
                    class="btns_gm btn_h"
                  >
                    已抢光
                  </div>
                  <div v-else class="btns_gm btn_h">{{ item.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <div
        v-else
        style="text-align: center; font-size: 24px; color: rgb(236, 99, 44)"
      >
        温馨提示：系统时间不准确，请修改电脑右下角时间为准确的北京时间，完成后重新刷新网站即可。
      </div>
      <!-- 广告区域 -->
      <div class="advertising">
        <a href="/index/pointsMall">
          <img src="../../assets/img/seckill/jfsc.png" alt="" />
        </a>
        <a href="/index/activityCenter">
          <img src="../../assets/img/seckill/hdzx.png" alt="" />
        </a>
      </div>
      <div class="btnBox">
        <a href="/index">返回首页</a>
        <a href="/index/cart">去购物车</a>
      </div>
    </div>
    <!-- loading -->
    <div class="loadingBox" v-if="loading">
      <div class="fulfilling-bouncing-circle-spinner">
        <div class="circle"></div>
        <div class="orbit">
          <img src="../../assets/img/seckill/logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      member_id: "",
      token: "",
      timer: { days: "00", hours: "00", minutes: "00", seconds: "00" },
      timeText: "距开始",
      btnText: "即将开始",
      seckill: {}, //当前显示的时间数据
      timeArr: [],
      timeSelectd: 1, //活动秒杀的选中id
      interval: null, //定时器状态
      goodsData_bk: [], //爆款秒杀的渲染数据
      goodsData_xs: [], //限时秒杀的渲染数据
      timeIsTrue: true,
      loading: false,
      limit_str:""
    };
  },
  mounted() {
    this.getTimer("start");
  },
  methods: {
    // 获取秒杀时间分段
    getTimer(type) {
      let that = this;
      this.$api("goods.seckill", { id: this.timeSelectd }).then((res) => {
        if (res.code == 200) {
          this.limit_str = res.data.limit_str;
          this.timeArr = res.data.hot_area;
          this.goodsData_bk = res.data.hot_goods;
          this.goodsData_xs = res.data.seckill_area;
          if (type == "start") {
            if (this.timeArr.length > 1) {
              console.log(type, "type");
              that.seckill = that.judgingTimePeriod(that.timeArr);
            } else {
              that.seckill = that.timeArr[0];
            }
            that.timeSelectd = that.seckill.id;
          }
          this.startCountdown();
          that.isTime(res.time);
          //   that.getGoods();
        } else {
          that.warning(res);
        }
      });
    },
    // 判断应该展示哪个时间段的秒杀
    judgingTimePeriod(tiemArr) {
      var nowTime = Date.now() / 1000;
      console.log(nowTime);
      var inTime = {};
      tiemArr.forEach(function (e) {
        if (e.start_time <= nowTime && nowTime <= e.countdown_time) {
          inTime = e;
        }
      });
      if (!inTime.id) {
        return tiemArr[0];
      }
      return inTime;
    },
    // 判断当前时间是否正确
    isTime(time) {
      var nowTime = Date.now();
      var poor = Math.abs(time * 1000 - nowTime);
      if (poor > 1800000) {
        this.timeIsTrue = false;
      }
    },
    // 加入购物车
    addGoods(item) {
      var that = this;
      this.$api("goods.addCart", {
        menu_id: that.timeSelectd,
        id: item.id,
        number: item.regular_num,
      }).then(function (res) {
        if (res.code == 200) {
          item.can_buy_str = "已参与";
          that.$message({
            message: "加入购物车成功",
            type: "success",
            offset: "380",
            duration: 1400,
          });
        } else {
          that.warning(res);
        }
        that.loading = false;
      });
    },
    // 购买按钮事件
    buyGoods(item) {
      if (this.btnText == "立即购买" && item.show_cart == "101") {
        this.addGoods(item);
      }
    },
    // 启动定时器
    startCountdown() {
      this.updateCountdown(); // 首先立即更新倒计时
      this.interval = setInterval(this.updateCountdown, 1000);
    },
    // 处理时间
    handleTime(time) {
      var date = new Date(time * 1000);
      var d = date.getHours();
      var m = date.getMinutes();
      d = this.padZero(d);
      m = this.padZero(m);
      return d + ":" + m;
    },
    // 倒计时
    updateCountdown() {
      var currentDate = new Date().getTime();
      var remainingTime = "";
      if (this.seckill.start_time * 1000 > currentDate) {
        this.timeText = "距开始";
        this.btnText = "即将开始";
        remainingTime = this.seckill.start_time * 1000 - currentDate;
      } else if (this.seckill.countdown_time * 1000 >= currentDate) {
        this.timeText = "距结束";
        this.btnText = "立即购买";
        remainingTime = this.seckill.countdown_time * 1000 - currentDate;
      } else {
        this.timeText = "已结束";
        this.btnText = "已结束";
      }

      // 将剩余时间转换为天、小时、分钟和秒
      var days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      // 在个位数前加上前导零
      days = this.padZero(days);
      hours = this.padZero(hours);
      minutes = this.padZero(minutes);
      seconds = this.padZero(seconds);
      // console.log(
      //   days + "天 " + hours + "小时 " + minutes + "分钟 " + seconds + "秒"
      // );
      this.timer = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
      // 如果倒计时结束，则显示提示信息
      if (remainingTime <= 0) {
        clearInterval(this.interval);
        this.timer = {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
        this.timeText = "已结束";
      }
    },
    // 处理倒计时的个位数
    padZero: function (num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    // 计算剩余商品数量百分比
    setPercent: function (item) {
      if (item.sykc == 0) {
        return 0;
      }
      if (item.sykc > item.zskc) {
        return 100;
      }
      return (item.sykc / item.zskc) * 100;
    },
    // 切换活动时间段
    switchTime: function (item) {
      this.timeSelectd = item.id;
      this.seckill = item;
      clearInterval(this.interval);
      this.getTimer();
    },
    // 判断某个时间段活动的状态
    judgmentTime: function (item) {
      var currentDate = new Date().getTime();
      if (item.start_time * 1000 > currentDate) {
        return "即将开抢";
      } else if (item.countdown_time * 1000 >= currentDate) {
        return "已开抢";
      } else {
        return "已结束";
      }
    },
    // 判断限时秒杀开始时间
    setMs: function (beginDateStr, endDateStr) {
      if (beginDateStr && endDateStr) {
        var curDate = new Date(),
          beginDate = new Date(beginDateStr),
          endDate = new Date(endDateStr);
        if (curDate >= beginDate && curDate <= endDate) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    // 操作异常消息提示
    warning: function (res) {
      this.$message({
        message: res.desc,
        type: "warning",
        offset: "380",
        duration: 1400,
      });
    },
    // 跳转活动中心
    jumpEvents: function () {
      location.href = "/act.html";
    },
  },
};
</script>

<style lang="less" scoped>
#seckill {
  min-height: 100vh;
  padding-bottom: 1px;
  // background: linear-gradient(
  //   rgb(255, 102, 95) 25%,
  //   rgb(255, 102, 85) 50%,
  //   rgb(255, 102, 75) 75%,
  //   rgb(255, 81, 65) 100%
  // );
  background-color: #f6f6f6;
  //   span,
  //   div,
  //   ul,
  //   li,
  //   p {
  //     color: #333;
  //     margin: 0;
  //     padding: 0;
  //     font-size: 12px;
  //   }
  .head {
    width: 100%;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
    }
    > span {
      position: absolute;
      top: 73%;
      left: 39%;
      font-size: 1.94vw;
      font-weight: 600;
      color: #fef2e2;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    .date_tag {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      > div {
        padding: 15px 32px;
        background-color: #ffaf3a;
        font-size: 36px;
        color: #fff;
        border-radius: 12px;
      }
    }
    .switch-box {
      display: flex;
      margin-top: 12px;
      .item {
        height: 60px;
        width: 228px;
        position: relative;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 3px;
        cursor: pointer;
        opacity: 0.9;
        > p {
          font-size: 16px;
          margin-left: 20px;
        }
        > div {
          position: absolute;
          right: 10px;
          top: 16px;
          min-width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #666;
          border: 1px solid #a3a3a3;
          border-radius: 2px;
          font-size: 14px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 2px;
        }
      }
      .active {
        background-color: #ffaf3a;
        > p {
          color: #fff;
        }
        > div {
          background-color: #fff;
          border: none;
          color: #ffaf3a;
        }
      }
    }
    .switch-box1 {
      display: flex;
      background-color: red;
      padding: 10px 10px 0 10px;
      margin: 20px 0;
      .item_box {
        display: flex;
        background-color: #f6f6f6;
        width: 220px;
        cursor: pointer;
        .blank_left {
          width: 10px;
          height: 100%;
          background-color: red;
        }
        .blank_right {
          width: 10px;
          height: 100%;
          background-color: red;
        }
      }
      .item {
        width: 200px;
        height: 70px;
        border-radius: 12px 12px 0 0;
        > p {
          text-align: center;
          font-weight: bold;
        }
        .time {
          font-size: 22px;
          color: #fff;
        }
        .text {
          color: #fff;
          white-space: nowrap;
        }
        .timer_num {
          color: #fff;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          > span {
            color: #fff;
            font-size: 16px;
          }
        }
      }
      .active {
        .item {
          background-color: #f6f6f6 !important;
          .time {
            color: red !important;
          }
          .text {
            color: red !important;
          }
          .timer_num {
            color: red !important;
            > span {
              color: red !important;
            }
          }
        }
        .blank_left {
          width: 20px;
          height: 100%;
          background-color: red;
          border-radius: 0 0 12px 0;
        }
        .blank_right {
          width: 20px;
          height: 100%;
          background-color: red;
          border-radius: 0 0 0 12px;
        }
      }
    }

    .tag_box {
      height: 80px;
      width: 260px;
      margin: 30px auto 10px;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      .tag_title {
        width: 100%;
        text-align: center;
        font-size: 30px;
        top: 0;
        left: 0;
        font-weight: bold;
        margin-top: 30px;
        position: absolute;
        color: #fff;
      }
    }
    .tip_text {
      margin: 0 auto 20px;
      text-align: center;
      font-size: 18px;
      color: #ff5246;
      font-weight: bold;
    }
    .tip_box {
      position: fixed;
      top: 38%;
      right: 52px;
      width: 235px;
      height: 236px;
      background-size: cover;
      .tip_title {
        text-align: center;
        font-size: 18px;
        margin: 8px 0;
        color: #fff;
      }
      .tip_text {
        padding: 20px 12px;
        P {
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }
    .timer {
      height: 70px;
      background-color: red;
      margin-bottom: 12px;
      .timerBox {
        font-size: 16px;
        color: #fff;
        transform: translate(20px, 20px);
        > span {
          display: inline-block;
          width: 36px;
          height: 30px;
          line-height: 30px;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          background-color: #fff;
          border-radius: 5px;
          color: #ffaf3a;
          margin: 0 7px;
        }
      }
    }
    .activity {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffaf3a;
      padding: 0 20px;
      margin: 30px 0;
      > p {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .title {
        font-size: 30px;
        letter-spacing: 3px;
      }
    }
    .bkms_box {
      width: 1200px;
      height: 740px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      background-image: url("../../assets/img/seckill/bkmsBox.png");
      .over_nowrop {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .goods_bk {
        display: flex;
        margin-top: 216px;
        padding: 0 75px;
        .goods_bk_img {
          width: 451px;
          height: 434px;
          border-radius: 30px;
        }
        .goods_bk_text {
          width: 500px;
          margin-left: 60px;
          > p {
            color: #4f4f4f;
            font-size: 24px;
            margin-top: 22px;
          }
          .title {
            font-size: 49px;
            color: #f03627;
            font-weight: 600;
          }
          .ggxq {
            display: flex;
            > span {
              max-width: 50%;
              min-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 24px;
              color: #4f4f4f;
            }
          }
          .bkms_kcxl {
            display: flex;
            margin-top: 22px;
            > span {
              font-size: 24px;
              min-width: 50%;
              max-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #4f4f4f;
            }
            .btn_xl {
              background-image: linear-gradient(
                to right,
                #ff512f 0%,
                #ff525c 51%,
                #ff512f 100%
              );
              color: #fff;
              padding: 1px 6px;
              border-radius: 5px;
              font-size: 12px;
              display: flex;
              align-items: center;
              > p {
                color: #fff;
                font-size: 20px;
                &:nth-child(2) {
                  background-color: #fff;
                  color: #f54c4c;
                  font-size: 20px;
                  padding: 0 5px;
                  height: 22px;
                  line-height: 22px;
                  border-radius: 3px;
                  margin-left: 5px;
                }
              }
            }
          }
          .bkms_btn {
            width: 373px;
            height: 74px;
            margin: 30px auto 0;
            cursor: pointer;
            background-image: linear-gradient(0deg, #ee3121 0%, #f84a3f 100%),
              linear-gradient(#ffffff, #ffffff);
            border-radius: 36px;
            position: relative;
            display: flex;
            .Begone {
              text-align: center;
              width: 100%;
              line-height: 74px;
              font-size: 28px;
              letter-spacing: 10px;
              color: #fff;
              font-weight: 600;
              background-color: #908989;
              border-radius: 100px;
            }
            > img {
              position: absolute;
              top: 12px;
              right: 40px;
            }
            > span {
              color: #fff;
              vertical-align: bottom;
              &:nth-child(1) {
                font-size: 18px;
                margin-left: 22px;
                line-height: 107px;
              }
              &:nth-child(2) {
                font-weight: 600;
                font-size: 60px;
              }
            }
          }
          .el-progress-bar__outer {
            border: 1px solid #f13a3a;
            background-color: #fff !important;
          }
          .actice_yqg .el-progress-bar__outer {
            background-color: rgb(235, 238, 245) !important;
            border: none;
          }
          .el-progress {
            width: 400px;
            cursor: pointer;
            margin: 40px auto 0;
          }
        }
      }
      .bkms_tip {
        font-size: 24px;
        color: #373737;
        margin-top: 20px;
        text-align: center;
      }
    }
    .bkms_box_two {
      width: 1217px;
      height: 623px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      display: flex;
      justify-content: space-around;
      padding: 0 23px;
      box-sizing: border-box;
      position: relative;
      background-image: url("../../assets/img/seckill/bkmsBox_two.png");
      .over_nowrop {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .goods_bk {
        display: flex;
        align-items: center;
        margin-top: 216px;
        background-color: #ffd6c1;
        width: 574px;
        height: 325px;
        background-color: #ffd6c1;
        border-radius: 45px 30px 30px 30px;
        .goods_bk_img {
          width: 299px;
          height: 289px;
          border-radius: 30px;
          margin-left: 10px;
        }
        .goods_bk_text {
          width: 257px;
          margin-left: 15px;
          > p {
            color: #4f4f4f;
            font-size: 16px;
            margin-top: 15px;
          }
          .title {
            font-size: 24px;
            color: #f03627;
            font-weight: 600;
          }
          .ggxq {
            display: flex;
            > span {
              max-width: 50%;
              min-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              color: #4f4f4f;
            }
          }
          .bkms_kcxl {
            display: flex;
            margin-top: 15px;
            > span {
              font-size: 16px;
              min-width: 50%;
              max-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #4f4f4f;
            }
            .btn_xl {
              background-image: linear-gradient(
                to right,
                #ff512f 0%,
                #ff525c 51%,
                #ff512f 100%
              );
              color: #fff;
              padding: 1px 6px;
              border-radius: 5px;
              font-size: 12px;
              display: flex;
              align-items: center;
              > p {
                color: #fff;
                font-size: 15px;
                &:nth-child(2) {
                  background-color: #fff;
                  color: #f54c4c;
                  font-size: 15px;
                  padding: 0 4px;
                  height: 17px;
                  line-height: 17px;
                  border-radius: 3px;
                  margin-left: 5px;
                }
              }
            }
          }
          .bkms_btn {
            width: 196px;
            height: 40px;
            margin-top: 20px;
            cursor: pointer;
            background-image: linear-gradient(0deg, #ee3121 0%, #f84a3f 100%),
              linear-gradient(#ffffff, #ffffff);
            border-radius: 36px;
            position: relative;
            display: flex;
            .Begone {
              text-align: center;
              width: 100%;
              line-height: 40px;
              font-size: 24px;
              letter-spacing: 8px;
              color: #fff;
              font-weight: 600;
              background-color: #908989;
              border-radius: 100px;
            }
            > img {
              position: absolute;
              top: 7px;
              right: 19px;
              width: 28px;
              height: 28px;
            }
            > span {
              color: #fff;
              vertical-align: bottom;
              &:nth-child(1) {
                font-size: 12px;
                margin-left: 20px;
                line-height: 58px;
              }
              &:nth-child(2) {
                font-weight: 600;
                font-size: 31px;
              }
            }
          }
          .el-progress-bar__outer {
            border: 1px solid #f13a3a;
            background-color: #fff !important;
          }
          .actice_yqg .el-progress-bar__outer {
            background-color: rgb(235, 238, 245) !important;
            border: none;
          }
          .el-progress {
            width: 197px;
            cursor: pointer;
            margin-top: 16px;
          }
        }
      }
      .bkms_tip_two {
        font-size: 20px;
        color: #373737;
        margin-top: 20px;
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
      }
    }
    .bkms_box_three {
      width: 1217px;
      height: 887px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      display: flex;
      justify-content: space-around;
      padding: 0 23px;
      box-sizing: border-box;
      position: relative;
      background-image: url("../../assets/img/seckill/bkmsBox_three.png");
      .over_nowrop {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .goods_bk {
        margin-top: 224px;
        background-color: #ffd6c1;
        width: 376px;
        height: 580px;
        background-color: #ffd6c1;
        border-radius: 20px;
        .goods_bk_img {
          width: 351px;
          height: 337px;
          border-radius: 10px;
          display: block;
          margin: 13px auto;
        }
        .goods_bk_text {
          width: 100%;
          box-sizing: border-box;
          padding-left: 15px;
          > p {
            color: #4f4f4f;
            font-size: 16px;
            margin-top: 15px;
          }
          .title {
            font-size: 24px;
            color: #f03627;
            font-weight: 600;
          }
          .ggxq {
            display: flex;
            > span {
              max-width: 50%;
              min-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              color: #4f4f4f;
            }
          }

          .bkms_kcxl {
            display: flex;
            margin-top: 15px;
            > span {
              font-size: 16px;
              min-width: 50%;
              max-width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #4f4f4f;
            }
            .btn_xl {
              background-image: linear-gradient(
                to right,
                #ff512f 0%,
                #ff525c 51%,
                #ff512f 100%
              );
              color: #fff;
              padding: 1px 6px;
              border-radius: 5px;
              font-size: 12px;
              display: flex;
              align-items: center;
              > p {
                color: #fff;
                font-size: 15px;
                &:nth-child(2) {
                  background-color: #fff;
                  color: #f54c4c;
                  font-size: 15px;
                  padding: 0 4px;
                  height: 17px;
                  line-height: 17px;
                  border-radius: 3px;
                  margin-left: 5px;
                }
              }
            }
          }
          .btn_three {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-right: 15px;
          }
          .bkms_btn {
            width: 196px;
            height: 40px;
            margin-top: 20px;
            cursor: pointer;
            background-image: linear-gradient(0deg, #ee3121 0%, #f84a3f 100%),
              linear-gradient(#ffffff, #ffffff);
            border-radius: 36px;
            position: relative;
            display: flex;
            .Begone {
              text-align: center;
              width: 100%;
              line-height: 40px;
              font-size: 24px;
              letter-spacing: 8px;
              color: #fff;
              font-weight: 600;
              background-color: #908989;
              border-radius: 100px;
            }
            > img {
              position: absolute;
              top: 7px;
              right: 14px;
              width: 28px;
              height: 28px;
            }
            > span {
              color: #fff;
              vertical-align: bottom;
              &:nth-child(1) {
                font-size: 12px;
                margin-left: 20px;
                line-height: 58px;
              }
              &:nth-child(2) {
                font-weight: 600;
                font-size: 26px;
                line-height: 47px;
              }
            }
          }
          .el-progress-bar__outer {
            border: 1px solid #f13a3a;
            background-color: #fff !important;
          }
          .actice_yqg .el-progress-bar__outer {
            background-color: rgb(235, 238, 245) !important;
            border: none;
          }
          .el-progress {
            width: 122px;
            cursor: pointer;
            margin-top: 16px;
          }
        }
      }
      .bkms_tip_two {
        font-size: 20px;
        color: #373737;
        margin-top: 20px;
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
      }
    }
    .goodsbox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 234px;
        background-color: #fff;
        padding: 0 10px 10px;
        box-sizing: border-box;
        margin-bottom: 8px;
        position: relative;
        margin-right: 7px;
        &:nth-child(5n) {
          margin-right: 0px;
        }
        .tag_img {
          position: absolute;
          top: 1px;
          left: 2px;
          height: 55px;
        }
        .imgbox {
          // padding: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          .bkms {
            width: 190px;
            height: 180px;
            position: absolute;
            top: 0;
            left: 0;
          }
          > img {
            width: 180px;
            height: 180px;
          }
        }
        .textbox {
          // margin-left: 30px;
          // width: 248px;
          > p {
            margin-top: 6px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap !important;
            font-size: 13px;
            .widSpan {
              display: inline-block;
              width: 120px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap !important;
              vertical-align: text-top;
              font-size: 13px;
            }

            span {
              font-size: 13px;
            }
          }
          .kcxl {
            display: flex;
            align-items: center;
            margin-top: 6px;
            .btn_xl {
              background-image: linear-gradient(
                to right,
                #ff512f 0%,
                #ff525c 51%,
                #ff512f 100%
              );
              color: #fff;
              padding: 1px 2px;
              border-radius: 5px;
              font-size: 12px;
              display: flex;
              margin-left: 10px;
              align-items: center;
              > p {
                color: #fff;
                &:nth-child(2) {
                  background-color: #fff;
                  color: #f54c4c;
                  font-size: 12px;
                  padding: 0 4px;
                  height: 14px;
                  line-height: 14px;
                  border-radius: 3px;
                  margin-left: 5px;
                }
              }
            }
          }
          .priceBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > div {
              display: flex;
              align-items: center;
            }
          }
          .red_price {
            font-size: 22px;
            font-weight: bold;
            color: red;
          }
          .title {
            font-size: 14px;
            color: #4e4e4e;
            font-weight: 700;
          }
          .dashedLine {
            margin-top: 15px;
            border-top: 1px dashed #ccc;
          }
          .tag {
            color: #e01222;
            > span {
              margin-right: 10px;
              display: inline-block;
              line-height: 18px;
              padding: 0 3px;
              color: #fff;
              font-size: 13px;
              background-color: #e01222;
            }
          }
          .btns_gm {
            width: 90px;
            line-height: 30px;
            height: 30px;
            padding: 0;
            color: #e01222;
            border: 1px solid #e01222;
            border-radius: 0;
            // margin: 8px auto 0;
            text-align: center;
            font-size: 15px;
            cursor: pointer;
            transition: all 0.25s;
            border-radius: 8px;
            &:hover {
              background-color: rgba(255, 2, 2, 0.15);
            }
          }
          .btn_h {
            border: 1px solid #999;
            color: #999;
            &:hover {
              background-color: #fff;
            }
          }

          .el-progress-bar__outer {
            border: 1px solid #f13a3a;
            background-color: #fff !important;
          }
          .actice_yqg .el-progress-bar__outer {
            background-color: rgb(235, 238, 245) !important;
            border: none;
          }
          .el-progress {
            width: 115px;
            cursor: pointer;
          }
        }
      }
    }
    .advertising {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 30px 0;
      > a {
        display: block;
        text-decoration: none;
        outline: none;
        width: 590px;
        height: 250px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .btnBox {
      text-align: center;
      margin: 30px 0 20px;
      > a {
        display: inline-block;
        width: 220px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        border-radius: 10px;
        background-color: #f6283c;
        text-decoration: none;
        margin: 0 40px 30px;
      }
    }
  }
  // loading样式
  .loadingBox {
    width: 100px;
    height: 100px;
    margin: auto;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .fulfilling-bouncing-circle-spinner,
    .fulfilling-bouncing-circle-spinner * {
      box-sizing: border-box;
    }
    .fulfilling-bouncing-circle-spinner .orbit img {
      width: 70%;
      height: 70%;
      border-radius: 50%;
    }
  }
}
.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  display: none;
}
.numTip {
  font-size: 14px;
}
.numTip.is-dark {
  background: #f13a3a;
}
.numTip .popper__arrow,
.numTip .popper__arrow::after {
  border-top-color: #f13a3a !important;
}
</style>